body {
  margin: 0;
  font-family: 'Lucida Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f6f0;
  color: #3f3f3f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, .spanLink {
  color: #3f3f3f;
  text-decoration: none;
}

a:hover, .spanLink:hover {
  color: #949494;
  text-decoration: none;
}

input:focus {
  outline: none
}

.submitButton {
  text-align: center;
}

.searchIcon {
  width: 4.5em;
}

.pointer {
  cursor: pointer;
}

.blueText {
  color: #4285f4;
}

.redText {
  color: #ea4335;
}

.yellowText {
  color: #fbbc03;
}

.greenText {
  color: #33a853;
}

.textAlignCenter {
  text-align: center;
}

.spacer {
  margin-top: 7vh;
}

.spacerLg {
  margin-top: 10vh;
}

.searchedGenesContainer {
  width: 30vw;
  visibility: hidden;
  animation: searchContainerFadeIn;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  -webkit-animation: searchContainerFadeIn;
  -webkit-animation-delay: 2s;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  padding-left: 10vh;
}

.pageTrackerContainer{
  visibility: hidden;
  animation: searchContainerFadeIn;
  animation-delay:2s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  -webkit-animation: searchContainerFadeIn;
  -webkit-animation-delay: 2s;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  width: fit-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  padding-left: 10vh;
}


.searchHeaderContainer{
  display: flex;
  justify-content: center;
}

.searchHeader{
  font-weight: 600;
  text-align: center !important;
  width: 90%;
}

.section_search-container{
  margin-top: 50px;
  max-width: 700px;
  position: relative;
}

.section_search-container-flex{
  display: flex;
  flex-direction: row;
  width: 80vw;
  justify-content: center;
}

.tab-column{
  width: 30vw;
}
.searchBar-column{
  width: 45vw;
}

.search-container{
  display: flex;
}


.section_search-container span {
  border-left: 2px solid#64ffda;
  font-size: 12px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 15px;
  display: inline-block;
  width: 2px;
  height: 42px;
  transition: transform 0.5s;
}

.section_search-container span.index1-chosen {
  transform: translateY(0px);
}
.section_search-container span.index2-chosen {
  transform: translateY(42px);
}
.section_search-container span.index3-chosen {
  transform: translateY(84px);
}
.section_search-container span.index4-chosen {
  transform: translateY(126px);
}
/* 
section_search-styledTab{
  width: 25%;
} */

.section_search-styledTabList{
  max-width: 200px;
  padding: 0;
}


.tab_button {  
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  border-left: 2px solid #8892b0;
  outline: none;
  font-family: "Menlo";
  font-size: 17px;
  height: 42px;
  width: 100%;
  text-align: left;
  padding: 0 20px;
}
.tab_button:hover,
.tab_button:focus {
  background-color: #F8F8F8;
}

.tab_button:active,
.tab_button:focus{
  color: #33a853;
  outline: 0px;
}


@keyframes searchContainerFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes searchContainerFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.searchedGenesHeader {
  border-bottom: 1px solid #686868;
  font-size: 0.9em;
  font-weight: 600;
  text-align: center;
  width: 90%;
  padding-left: 5vh;
  /*background-color: #eee7d2;*/
}

.evenRow {
  width: 90%;
  font-size: 0.8em;
  padding-left: 1em;
  /*background-color: #f8f6f0;*/
}

.oddRow {
  width: 90%;
  font-size: 0.8em;
  padding-left: 1em;
  /*background-color: #eee7d2;*/
}

/* development banner */

.developmentBanner {
  background-color:#ea3571;
  color: #f8f6f0;
  font-weight: 700;
}

/* end development banner */

/* navbar */

.navLink {
  width: 5em;
  margin-left: 1em;
  font-weight: 700;
  cursor: pointer;
}

.navLeftBuffer, .leftBuffer {
  margin-left: 2em;
}

#navRow {
  animation-name: navRowFadeIn;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: navRowFadeIn;
  -webkit-animation-delay: 2s;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes navRowFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;;
  }
  100% {
    opacity: 100;
    visibility: visible;
  }
}

@-webkit-keyframes navRowFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;;
  }
  100% {
    opacity: 100;
    visibility: visible;
  }
}

/* end navbar */

/* home */

.logoMain {
  width: 4vw;
  margin-left: 85vw;
  margin-top: 2vh;
  margin-bottom: 5vh;
  text-align: center;
  visibility: hidden;
  animation: logoFadeIn;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation: logoFadeIn;
  -webkit-animation-delay: 1.5s;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
}

.moffittLogoMain {
  width: 5vw;
  margin-left: 1vw;
  margin-top: 2vh;
  margin-bottom: 5vh;
  text-align: center;
  visibility: hidden;
  animation: logoFadeIn;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation: logoFadeIn;
  -webkit-animation-delay: 1.5s;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes logoFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes logoFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.geneSuggestionContainer {
  border-left: 1px solid #3f3f3f;
  border-right: 1px solid #3f3f3f;
  border-bottom: 1px solid #3f3f3f;
  position: absolute;
  width: 40vw;
  z-index: 5;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #f8f6f0;
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: 3px 3px 5px #3f3f3f;
  max-height: 30vh;
  overflow-y: auto;
}

.geneSuggestionContainerOverflow {
  border-left: 1px solid #3f3f3f;
  border-right: 1px solid #3f3f3f;
  border-bottom: 1px solid #3f3f3f;
  position: absolute;
  height: 30vh;
  width: 40vw;
  z-index: 5;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #f8f6f0;
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: 3px 3px 5px #3f3f3f;
  overflow-y: auto;
}

.suggestedGene:hover {
  background-color: #3f3f3f;
  color: #f8f6f0;
  cursor: pointer;
}

.exampleLink:hover {
  font-weight: 700;
  text-decoration: underline;   
}

@media screen and (max-width: 700px) {
  #multipleGenesText {
    margin-left: 0vw;
    margin-top: 0px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px) {
  #multipleGenesText {
    margin-left: 20vw;
    margin-top: -15px;
  }
}

@media screen and (min-width: 1001px) {
  #multipleGenesText {
    margin-left: 25vw;
    margin-top: -15px;
  }
}

@media screen and (max-width: 860px){
  .search-container{
    margin-left: auto;
    margin-right: auto;
  }
  .section_search-container-flex {
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .tab-column{
    width: 65vw;
    margin-left: 10vw;
  }
  .searchBar-column{
    width: 95vw;
  }
  .searchedGenesContainer{
    width: 50vw;
    margin-right:10vw;
  }
}


#homePageTitle {
  animation-name: titleSlideUp;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: titleSlideUp;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  text-align: center;
  font-size: 4vw;
  font-weight: 700;
  margin-top: 0vh;
  visibility: hidden;
}

@keyframes titleSlideUp {
  0% {
    margin-top: 25vh;
    visibility: hidden;
    opacity: 0;
  }
  100% {
    margin-top: 0vh;
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes titleSlideUp {
  0% {
    margin-top: 25vh;
  }
  100% {
    margin-top: 0vh;
  }
}

#geneExamplesNonAnim {
  font-size: 0.75em;
  width: 40vw;
  color:#686868;
}


#geneExamples {
  animation-name: geneExampleSlideUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: geneExampleSlideUp;
  -webkit-animation-delay: 1s;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  font-size: 0.75em;
  width: 40vw;
  color:#686868;
}

@keyframes geneExampleSlideUp {
  0% {
    margin-top: 20vh;
    opacity: 0;
  }
  100% {
    margin-top: 0vh;
    opacity: 100;
    visibility: visible;
  }
}

@-webkit-keyframes geneExampleSlideUp {
  0% {
    margin-top: 20vh;
    opacity: 0;
  }
  100% {
    margin-top: 0vh;
    opacity: 100;
    visibility: visible;
  }
}




/*
.iconClick {
  animation-name: searchIconSlideUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: searchIconSlideUp;
  -webkit-animation-delay: 1.5s;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  cursor: pointer;
  visibility: hidden;
}

@keyframes searchIconSlideUp {
  0% {
    margin-top: 20vh;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    margin-top: 0vh;
    opacity: 100;
    visibility: visible;
  }
}

@-webkit-keyframes searchIconSlideUp {
  0% {
    margin-top: 20vh;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    margin-top: 0vh;
    opacity: 100;
    visibility: visible;
  }
}
*/

.searchPlaneIcon {
  animation-name: searchPlaneIconFadeIn;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: searchPlaneIconFadeIn;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  width: 4em;
  margin-top: 5vh;
  margin-left: -5em;
  visibility: hidden;
}

@keyframes searchPlaneIconFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 100;
  }
}

@-webkit-keyframes searchPlaneIconFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 100;
  }
}

.searchPlaneIcon2 {
  width: 4em;
  margin-top: 5vh;
  margin-left: -5em;
}

.searchPlaneIcon2:hover {
  animation-name: searchPlaneIconFlashSize;
  animation-duration: 0.25s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: searchPlaneIconFlashSize;
  -webkit-animation-duration: 0.25s;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-timing-function: ease-in-out;
  width: 4em;
  margin-top: 5vh;
  margin-left: -5em;
  cursor: pointer;
}

@keyframes searchPlaneIconFlashSize {
  0% {
    width: 4em;
  }
  50% {
    width: 4.2em;
    margin-right: -0.2em;
    margin-bottom: -0.2em;
  }
  100% {
    width: 4em;
  }
}

@-webkit-keyframes searchPlaneIconFlashSize {
  0% {
    width: 4em;
  }
  50% {
    width: 4.2em;
    margin-right: -0.2em;
    margin-bottom: -0.2em;
  }
  100% {
    width: 4em;
  }
}

/*
.iconClick2:hover {
  animation-name: flashSize;
  animation-duration: 0.25s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: flashSize;
  -webkit-animation-duration: 0.25s;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-timing-function: ease-in-out;
  cursor: pointer;
  visibility: visible;
}

@keyframes flashSize {
  0% {
    width: 4.5em;
  }
  50% {
    width: 4.7em;
  }
  100% {
    width: 4.5em;
  }
}

@-webkit-keyframes flashSize {
  0% {
    width: 4.5em;
  }
  50% {
    width: 4.7em;
  }
  100% {
    width: 4.5em;
  }
}
*/

.geneSearchBarNonAnim {
  margin-top: 5vh;
  margin-left: -1em;
  width: 40vw;
  height: 3em;
  color: #3f3f3f;
  background-color: #f8f6f0;
  border: 1px solid #3f3f3f;
  border-radius: 3em;
  /* visibility: hidden; */
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0.5vh;
}


.geneSearchBar {
  animation-name: grow;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  margin-top: 5vh;
  margin-left: -1em;
  width: 1vw;
  height: 3em;
  color: #3f3f3f;
  background-color: #f8f6f0;
  border: 1px solid #3f3f3f;
  border-radius: 3em;
  visibility: hidden;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0.5vh;
}

@keyframes grow {
  0% {
    width: 1vw;
    visibility: visible;
  }
  100% {
    width: 40vw;
    visibility: visible;
  }
}

@-webkit-keyframes grow {
  0% {
    width: 1vw;
    visibility: visible;
  }
  100% {
    width: 40vw;
    visibility: visible;
  }
}

.mainCircleLoader {
  margin-top: 35vh;
  margin-left: 47.5vw;
}

.mainLoadingText {
  margin-top: 0.5em; 
  font-weight: 700; 
}

.loadingDot {
  font-size: 2em;
}

#errorMessage {
  text-align: center;
  height: 20px;
}

/* end home */

/* circle loader */

.circleLoader {
  animation-name: loader;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: loader;
  -webkit-animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  background: #f8f6f0;
  height: 2vw;
  width: 2vw;
  border: 2vw solid;
  border-radius: 25%;
  border-color: #4285f4 #ea4335 #fbbc03 #33a853;
  transform: rotate(45deg);
}

@keyframes loader {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* end circle loader */

/* footer */

.moffittLogo {
  margin-top: 1em;
  width: 14vw;
}

.footerContainerNoAnim {
  margin-top: 7vh;
  text-align: center;
  font-size: 0.8em;
}

.footerContainer {
  visibility: hidden;
  margin-top: 7vh;
  text-align: center;
  font-size: 0.8em;
  opacity: 0;
  animation-name: FooterFadeIn;
  animation-delay: 4s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  -webkit-animation-name: navRowFadeIn;
  -webkit-animation-delay: 4s;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes FooterFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes FooterFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;;
  }
  100% {
    opacity: 100;
    visibility: visible;
  }
}

.socialMediaIcons {
  width: 2em;
  margin-right: 0.25em;
  cursor: pointer;
}

.verticalDivider {
  width: 2em;
  margin-left: 1em;
  margin-right: 1em;
  border-left: 2px solid #3f3f3f;
}

/* end footer */

/* contacts */

.leftBufferContact {
  margin-left: 4em;
}

.emailContainer {
  width: 15em;
}

.customAnchor {
  cursor: pointer;
}

.customAnchor:hover {
  cursor: pointer;
  text-decoration: underline;
}

.contactFormLayoutSm {
  width: 30vw;
  margin-left: 10vw;
}

.contactFormLayout {
  width: 80vw;
  margin-left: 10vw;
}

#sendContactButton {
  cursor: pointer;
  text-align: center;
  width: 10vw;
  margin-left: 45vw;
  font-weight: 600;
  color: #f8f6f0;
  background-color: #4285f4;
  border: 1px solid #4285f4;
}

/* end contacts */

/* news */

.newsDateContainer {
  min-width: 15vw;
}

.newsMessageContainer {
  padding-left: 2em;
}

/* end news */

/* about */

.teamNameContainer {
 
}

.teamRoleContainer {
  
  font-size: 0.75em;
}

/* end about */